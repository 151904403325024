import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { MDBBtn } from "mdb-react-ui-kit";
// import CtaButton from "./ctabutton";
// import NavButton from "./navButton";
import "./main.css";

const mainScreen = ({ newClick, searchClick }) => {
  return (
    <div className="main-div">
      <div className="form-outline mb-2">
        <Link to="/surgonLogin" className="main-link">
          <button className="button">CIRUJANO</button>
        </Link>
      </div>
      <div className="form-outline mb-2">
        <Link to="/patientLogin" className="main-link">
          <button className="button">PACIENTE</button>
        </Link>
      </div>
    </div>
  );
};

//  class mainScreen extends React.Component {
//      render() {
//          return (
//             <h1>Main screen</h1>
//             );

//      }
//  }

export default mainScreen;
