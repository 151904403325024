import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { MDBBtn } from "mdb-react-ui-kit";
import "../main.css";
import "../surgeon/loginView.css";
import { BsFillPersonFill } from "react-icons/bs";

const suregonHome = () => {
  return (
    <div className="loginView-main-div">
      {/* <div className="profile-default">
        <BsFillPersonFill />
      </div> */}
      {/* <h4 className="mb-5">CIRUJANO</h4> */}
      <div className="form-outline mb-4">
        <Link to="/newPatient" className="main-link">
          <button className="button">NUEVO</button>
        </Link>
      </div>
      <div className="form-outline mb-4">
        <Link to="/searchPatientForm" className="main-link">
          <button className="button">BUSCAR</button>
        </Link>
      </div>
      <div className="form-outline mb-4">
        <a href="http://ec2-52-91-1-11.compute-1.amazonaws.com/reportes/r.html" target="_blank" className="main-link">
          <button className="button">REPORTES</button>
        </a>
      </div>
    </div>
  );
};

export default suregonHome;
