import React, { PureComponent } from "react";
import { useField } from "formik";
// import "../../surgeon/loginView.css";
import styles from "./textInput.module.css";

const CustomTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className="form-outline">
        <label className="label mb-2" htmlFor={props.id || props.name}>
          {label}
        </label>
        <input
          className={`form-control ${styles.input}`}
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};

export default CustomTextInput;
