import React, { PureComponent, useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
// import { MDBBtn } from "mdb-react-ui-kit";
import { API_URL } from "../../Utils/constants";

const PatientInformation = ({getNextSurvey}) => {
        const navigate = useNavigate();
        const [patient, setPatient] = useState({});
        const { state } = useLocation();
        window.psicologicoview = true;
        useEffect(() => {
        setPatient(JSON.parse(localStorage.getItem("patient")));
        const requestOptions = {
        method: "POST",
        body: JSON.stringify({ password: state.patient.password }),
        };
        fetch(`${API_URL}patient/login`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
            if (!res._id) {
            return;
            }
            let patient = res;
            localStorage.setItem("patient", JSON.stringify(patient));
            setPatient(patient);
            console.log("localStorage ", localStorage);
            console.log("data ", res);
            console.log("localStorage ", localStorage);
            if (localStorage.getItem("surveyDone") != null) {
            localStorage.removeItem("surveyDone");
            localStorage.setItem("loadNext", "true");
            //window.location.reload(false);
            } 
        });
    }, []);

    const createdAt = new Date(state?.patient.createdAt);
    return (
        <>
                <div className="loginView-main-div">
            <h2
                style={{
                textAlign: "left",
                alignItems: "flex-start",
                justifySelf: "flex-start",
                }}
            >
                {state.patient.apellido}
            </h2>
            <div className="patientHomeTableDiv">
                <div className={"infoContainer"}>
                <span
                    style={{
                    fontSize: "16px",
                    color: "#c0c0c0",
                    fontWeight: "500",
                    }}
                >
                    Nº Historia
                </span>
                <span
                    style={{
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "500",
                    }}
                >
                    {state.patient.historia}
                </span>
                </div>
                <div className={"infoContainer"}>
                <span
                    style={{
                    fontSize: "16px",
                    color: "#c0c0c0",
                    fontWeight: "500",
                    }}
                >
                    Hospital
                </span>
                <span
                    style={{
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "500",
                    }}
                >
                    {state.patient.hospital}
                </span>
                </div>
                <div className={"infoContainer"}>
                <span
                    style={{
                    fontSize: "16px",
                    color: "#c0c0c0",
                    fontWeight: "500",
                    }}
                >
                    Fecha
                </span>
                <span
                    style={{
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "500",
                    }}
                >
                    {`${createdAt?.getDate()}-${
                    createdAt?.getMonth() + 1
                    }-${createdAt?.getFullYear()}`}
                </span>
                </div>
                <div className={"infoContainer"}>
                <span
                    style={{
                    fontSize: "16px",
                    color: "#c0c0c0",
                    fontWeight: "500",
                    }}
                >
                    Formularios
                </span>

                <span
                    style={{
                    fontSize: "16px",
                    color: "black",
                    fontWeight: "500",
                    }}
                >
                {state.patient.questionFiles.map(function (item){
                    let print = false;
                    let testshow = '';
                    if(item !== 'HAD' && item !== 'PCS' && item !== 'TSK'){
                        print = true;
                        testshow = item;
                    }else{
                        if(window.psicologicoview){
                        window.psicologicoview = false;
                        print = true;
                        testshow = 'Psicológicos';
                        } 
                    }
                    if(print){
                        return <span className="mx-1">{testshow}</span>;
                    }else{
                        return false; 
                    }
                }
            )}
                </span>
                </div>
            </div>
            <div
                onClick={() => {
                    getNextSurvey();
                }}
            >
                <button
                className="button"
                style={{ marginTop: "60px", fontSize: "16px" }}
                >
                Empezar
                </button>
            </div>
            </div>
        </>
    )
}

export default PatientInformation
