import React, { PureComponent } from "react";
import { useField } from "formik";
import "../../surgeon/loginView.css";

const CustomSelectInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className="form-outline mb-4 ">
        <label className="label" htmlFor={props.id || props.name}>
          {label}
        </label>
        <select className="form-control" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </>
  );
};

export default CustomSelectInput;
