import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomTextInput from "../FormUI/TextInput/index";
import CustomSelectInput from "../FormUI/SelectInput/index";
import { useNavigate } from "react-router-dom";
import "../surgeon/loginView.css";
import "../main.css";
import {
  BsArrowRightShort,
  BsChevronBarExpand,
  BsChevronExpand,
} from "react-icons/bs";
import styles from "./newPatient.module.css";
import { motion, AnimatePresence } from "framer-motion";
import { BiCheck } from "react-icons/bi";
import BackButton from "../backButton";

// const { motion } = Framer;

const NewPatient = (props) => {
  const navigate = useNavigate();
  const INITIAL_STATE = {
    name: "",
    apellido: "",
    historia: "",
    hospital: "SELECCIONE UNO",
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [open, setOpen] = useState(false);
  const [validMessague, setvalidMessague] = useState(false);
  const options = [
    "SELECCIONE UNO",
    "HOSPITAL DEXEUS",
    "HOSPITAL SANT PAU",
    "HOSPITAL PARC SALUT MAR",
    "RESPORT CLINIC",
    "OTROS",
  ];

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setOpen(false);
  };
  const FORM_VALIDATION = Yup.object({
    name: Yup.string().required("Required").min(3, "must be at least 3 characters"),
    apellido: Yup.string().required("Required").min(3, "must be at least 3 characters"),
    historia: Yup.number().required("Required"),
    // hospital: Yup.string()
    //   .oneOf([
    //     "HOSPITAL DEXEUS",
    //     "HOSPITAL SANT PAU",
    //     "HOSPITAL PARC SALUT MAR",
    //     "OTROS",
    //   ])
    //   //.required("Required"),
  });
  const ON_SUBMIT = async (values, { setSubmitting, resetForm }) => {
    console.log("value are ", values);
    if (values.hospital != "SELECCIONE UNO") {
      setTimeout(() => {
        // alert(JSON.stringify(values, null, 2));
        resetForm();
        setSubmitting(false);
        navigate("/newPatientTwo", { state: values });
      }, 300);
    } else {
      document.getElementById('mensajeValid').style.display = 'flex';
    }
  };

  if (localStorage.getItem("pacienteHijo") !== null) {
    localStorage.removeItem("pacienteHijo");
    localStorage.removeItem("pacienteHijoApellido");
    localStorage.removeItem("pacienteHijoHistoria");
    localStorage.removeItem("pacienteHijoLado");
    localStorage.removeItem("pacienteHijoName");
    localStorage.removeItem("pacienteHijoDiagnosticos");
    localStorage.removeItem("pacienteHijoTratamientos");
    localStorage.removeItem("pacienteHijoHospital");
  }
  if (localStorage.getItem("isKSSFUllIdPatient") !== null) {
    localStorage.removeItem("isKSSFUllIdPatient");
  }
  window.arrayTrata = [];
  window.arrayDiag = [];
  return (
    <div class="text-left loginView-main-div" style={{ position: "relative" }}>
      <div style={{ position: "absolute", top: "3px", left: "0" }}>
        <BackButton />
      </div>
      <h4 className="mb-2">NUEVO</h4>
      <Formik
        initialValues={{ ...INITIAL_STATE }}
        validationSchema={FORM_VALIDATION}
        onSubmit={ON_SUBMIT}  
      >
        {(props) => {
          const { setFieldValue } = props;
          return (
          <Form>
            <CustomTextInput
              // label="NOMBRE"
              type="text"
              name="name"
              placeholder="NOMBRE"
            />
            <CustomTextInput
              // label="APELLIDOS"
              type="text"
              name="apellido"
              placeholder="APELLIDOS"
            />

            <CustomTextInput
              // label="N• HISTORIA"
              type="number"
              selectInput
              name="historia"
              placeholder="N• HISTORIA"
            />

            {/* <div>
              <CustomSelectInput
                // label="Hospital"
                className="formSelect"
                name="hospital"
              >

                <option value="HOSPITAL DEXEUS">HOSPITAL DEXEUS</option>
                <option value="HOSPITAL SANT PAU">HOSPITAL SANT PAU</option>
                <option value="HOSPITAL PARC SALUT MAR">
                  HOSPITAL PARC SALUT MAR
                </option>
                <option value="OTROS">OTROS</option>
              </CustomSelectInput>
            </div> */}

            <div className={`${styles.dropdownContainer}`}>
              <div>
                <p className={`${styles.dropdownLabel}`}>HOSPITAL</p>
              </div>
              <div
                className={styles.selectedOption}
                onClick={() => setOpen(!open)}
              >
                <p>{selectedOption || "SELECCIONE UNO"}</p>
                <BsChevronExpand />
              </div>
              <AnimatePresence>
                {open && (
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{
                      scale: 1,
                    }}
                    exit={{
                      scale: 0,
                    }}
                    className={styles.selectContainer}
                    // style={{ background: "red", width: "50px", height: "50px" }}
                  >
                    {options.map((option, i) => (
                      <div
                        key={option}
                        className={`${styles.option}`}
                        onClick={() =>{
                          if (option != "SELECCIONE UNO") {
                            handleOptionSelect(option);
                            setFieldValue('hospital', option);
                          } else {
                            document.getElementById('mensajeValid').style.display = 'flex';
                          }
                        }}
                        style={{
                          borderEndEndRadius:
                            options?.length - 1 === i && "12px",
                          borderEndStartRadius:
                            options?.length - 1 === i && "12px",
                          borderTopLeftRadius: i === 0 && "12px",
                          borderTopRightRadius: i === 0 && "12px",
                          borderBottom:
                            options?.length - 1 !== i && "1px solid #22222240",
                        }}
                      >
                        {option === selectedOption && (
                          <span style={{ marginLeft: -15, marginRight: 5 }}>
                            <BiCheck size={18} />
                          </span>
                        )}
                        {option}
                      </div>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            {/* <div className="dropdown-section " name="hospital">
              <label>Hospital</label>
              <div className="dropdown">
                <div
                  className="dropdown-selected-option"
                  onClick={() => setOpen(!open)}
                >
                  {selectedOption || "HOSPITAL DEXEUS"}
                </div>
                {open && (
                  <div className="dropdown-options">
                    {options.map((option) => (
                      <div
                        key={option}
                        className={`dropdown-option ${
                          option === selectedOption ? "checked" : ""
                        }`}
                        onClick={() => handleOptionSelect(option)}
                      >
                        {option === selectedOption && (
                          <span className="checkmark">&#10003;</span>
                        )}
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div> */}
            
            <div id="mensajeValid" style={{ display: "none"}}>
              El hospital es requerido
            </div>


            <div class="form-group d-flex justify-content-center">
              <button type="submit" className="arrowButton">
                <div>{props.isSubmitting ? "Wait..." : "Siguiente"} </div>
                <div className="buttonArrow">
                  <BsArrowRightShort />
                </div>
              </button>
            </div>
          </Form>
        )}}
      </Formik>
    </div>
  );
};

export default NewPatient;
