import React, { PureComponent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import styles from "./confirmView.module.css";
import BackButton from "../backButton";

const ConfirmView = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [surgeon, setSurgeon] = useState();
  
  const [isKSSFUll, setIsKSSFUll] = useState();

  window.psicologicoview = true;
  function sharedCode(code) {
    if ("share" in navigator) {
      navigator.share({
        title: "Kneetest",
        text: 'Su código para realizar los test es el siguiente: ' + code,
        //url: 'http://kneetest.com/'
      })
    }
  };
  useEffect(() => {
    console.log("state ", state);
    const surge = localStorage.getItem("surgeon");
    const isKSSFUllvar = localStorage.getItem("isKSSFUll");

    if (isKSSFUllvar) {
      setIsKSSFUll(true);
    } else {
      setIsKSSFUll(false);
    }
    
    console.log("surgeon ", surge);
  }, []);
  return (
    <div class="dl-horizontal">
      <div style={{ display: "flex", alignItems: "center" }} className="mb-4">
        <BackButton />
        <h4 className=" text-center" style={{ marginLeft: "90px" }}>
          ESCALAS
        </h4>
      </div>
      <Formik
        initialValues={{ name: "" }}
        onSubmit={async (values) => {
          console.log("values are ", values);
        }}
      >
        <Form>
          <div className={styles.infoContainer}>
            <div onClick={() => {
                sharedCode(state.patient.password);
              }}>
              <span style={{ color: "#a3a0a4" }}>Codigo</span>
              <span
                style={{ color: "black", fontWeight: "600", fontSize: "17px" }}
              >
                {state.patient.password}
              </span>
            </div>

            {state.patient.questionFiles.map(function (item){
              let print = false;
              let testshow = '';
              if(item !== 'HAD' && item !== 'PCS' && item !== 'TSK'){
                print = true;
                testshow = item;
              }else{
                if(window.psicologicoview){
                  window.psicologicoview = false;
                  print = true;
                  testshow = 'Psicológicos';
                } 
              }
              if(print){
                return(
                  <div style={{ justifyContent: "center" }}>
                    <span
                      style={{
                        color: "#a3a0a4",
                        textAlign: "center !important",
                        fontSize: "17px",
                      }}
                      key={testshow}
                    >
                      {testshow}
                    </span>
                  </div>
                )
              }else{
                return false; 
              }
            }
            )}

            {state.patient.questionFiles.map(function (item){
              if(item == 'KSS' && !isKSSFUll) {
                return (
                  <div className="form-group d-flex justify-content-center my-4" id="botonKSS" key={item}>
                    <button className="button"  
                    onClick={() => {
                      localStorage.setItem("isKSSFUll", true);
                      localStorage.setItem("isKSSFUllIdPatient", state.patient._id);
                      setIsKSSFUll(true);
                      navigate("/surveyHome", { state: { name: 'KSS', isSurgeon: true }});
                    }}>
                      Test KSS
                    </button>
                  </div>
                )
              }
            })}
          </div>

          <div className="form-group d-flex justify-content-center my-4">
            <button
              type="submit"
              className="button"
              onClick={() => {
                navigate("/suregonHome");
              }}
              style={{ minWidth: 130, padding: "12px", fontSize: "17px" }}
            >
              Guardar
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ConfirmView;
