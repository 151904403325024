import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomTextInput from "../FormUI/TextInput/index";
import CustomSelectInput from "../FormUI/SelectInput/index";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../Utils/constants";
import "../main.css";
import "../surgeon/loginView.css";
import styles from "./searchForm.module.css";
import { BsChevronExpand } from "react-icons/bs";
import { AnimatePresence, motion } from "framer-motion";
import { BiCheck } from "react-icons/bi";
import BackButton from "../backButton";

const SearchForm = (props) => {
  const navigate = useNavigate();
  const INITIAL_STATE = {
    name: "",
    apellido: "",
    historia: "",
    hospital: "HOSPITAL DEXEUS",
    diagnostics: "",
    tratamientos: "",
    fecha: "",
  };
  const FORM_VALIDATION = Yup.object({
    name: Yup.string(),
    apellido: Yup.string(),
    historia: Yup.number(),
    hospital: Yup.string().oneOf([
      "HOSPITAL DEXEUS",
      "HOSPITAL SANT PAU",
      "HOSPITAL PARC SALUT MAR",
      "OTROS",
    ]),
    diagnostics: Yup.string(),
    tratamientos: Yup.string(),
    fecha: Yup.string(),
  });

  const [selectedOption, setSelectedOption] = useState(null);

  const [open, setOpen] = useState(false);
  const options = [
    "HOSPITAL DEXEUS",
    "HOSPITAL SANT PAU",
    "HOSPITAL PARC SALUT MAR",
    "OTROS",
  ];

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setOpen(false);
  };

  const ON_SUBMIT = async (values, { setSubmitting, resetForm }) => {
    setTimeout(() => {
      // alert(JSON.stringify(values, null, 2));
      const surgeon = JSON.parse(localStorage.getItem("surgeon"));
      console.log("value are ", values);
      const obj = { 
        surgeonId: surgeon._id,
        apellido: 'demo'
      };
      const requestOptions = {
        method: "GET",
        body: JSON.stringify(obj),
      };
      fetch(`${API_URL}surgeon/searchPatients?surgeonId=${surgeon._id}&apellido=${values.name}&historia=${values.historia}&tratamentios=${values.tratamientos}&diagnostic=${values.diagnostics}`)
        .then((response) => response.json())
        .then((res) => {
          let results = res;
          resetForm();
          setSubmitting(false);
          navigate("/patientListView", {
            state: { results },
          });
        });
    }, 300);
  };

  return (
    <div class="text-left" style={{ width: "400px" }}>
      <div style={{ display: "flex", alignItems: "center" }} className="mb-4">
        <BackButton />
        <h4 className=" text-center" style={{ marginLeft: "80px" }}>
          BUSCADOR
        </h4>
      </div>
      <Formik
        initialValues={{ ...INITIAL_STATE }}
        validationSchema={FORM_VALIDATION}
        onSubmit={ON_SUBMIT}
      >
        {(props) => (
          <Form>
            <CustomTextInput
              // label="NOMBRE"
              type="text"
              name="name"
              placeholder="APELLIDO"
            />

            <CustomTextInput
              // label="N• HISTORIA"
              type="text"
              name="historia"
              placeholder="N• HISTORIA"
            />
            {/* <CustomSelectInput
              label="Hospital"
              className="formSelect"
              name="hospital"
            >
              <option value="HOSPITAL DEXEUS">HOSPITAL DEXEUS</option>
              <option value="HOSPITAL SANT PAU">HOSPITAL SANT PAU</option>
              <option value="HOSPITAL PARC SALUT MAR">
                HOSPITAL PARC SALUT MAR
              </option>
              <option value="OTROS">OTROS</option>
            </CustomSelectInput> */}

            <div className={`${styles.dropdownContainer}`}>
              <div>
                <p className={`${styles.dropdownLabel}`}>HOSPITAL</p>
              </div>
              <div
                className={styles.selectedOption}
                onClick={() => setOpen(!open)}
              >
                <p>{selectedOption || "HOSPITAL DEXEUS"}</p>
                <BsChevronExpand />
              </div>
              <AnimatePresence>
                {open && (
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{
                      scale: 1,
                    }}
                    exit={{
                      scale: 0,
                    }}
                    className={styles.selectContainer}
                    // style={{ background: "red", width: "50px", height: "50px" }}
                  >
                    {options.map((option, i) => (
                      <div
                        key={option}
                        className={`${styles.option}`}
                        onClick={() => handleOptionSelect(option)}
                        style={{
                          borderEndEndRadius:
                            options?.length - 1 === i && "12px",
                          borderEndStartRadius:
                            options?.length - 1 === i && "12px",
                          borderTopLeftRadius: i === 0 && "12px",
                          borderTopRightRadius: i === 0 && "12px",
                          borderBottom:
                            options?.length - 1 !== i && "1px solid #22222240",
                        }}
                      >
                        {option === selectedOption && (
                          <span style={{ marginLeft: -15, marginRight: 5 }}>
                            <BiCheck size={18} />
                          </span>
                        )}
                        {option}
                      </div>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <CustomTextInput
              // label="Diagnostics"
              type="text"
              name="diagnostics"
              placeholder="Diagnostics"
            />
            <CustomTextInput
              // label="Tratamientos"
              type="text"
              name="tratamientos"
              placeholder="Tratamientos"
            />
            <CustomTextInput
              // label="Fecha"
              type="date"
              name="fecha"
              placeholder="Fecha"
            />
            <div class="form-group d-flex justify-content-center my-4">
              <button
                type="submit"
                className="button"
                style={{ minWidth: 130 }}
              >
                {props.isSubmitting ? "Wait..." : "BUSCADOR"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SearchForm;
