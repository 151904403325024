import React, { PureComponent, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
// import { MDBBtn } from "mdb-react-ui-kit";
import { API_URL } from "../../Utils/constants";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { MuiThemeProvider } from "@mui/material/styles";
import "../main.css";
import "../surgeon/loginView.css";
import styles from "./pickerOptionsView.module.css";
import { AnimatePresence, motion } from "framer-motion";

const TinyText = styled(Typography)({
  fontSize: "0.78rem",
  opacity: 0.38,
  fontWeight: 500,
  color: "#000000",
  letterSpacing: 0.2,
});

const PickerView = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  var sliderValue = 0;
  useEffect(() => {
    console.log("state ", state);
    // fetch(`${API_URL}getSurvey/${state.name}`)
    // .then(res =>res.json())
    // .then(res => {
    //     console.log('data ', res);
    //     setSurvey(res);
    // });
  }, []);

  const theme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          rail: {
            backgroundColor: "#BDBDBD",
          },
          track: {
            color: "#399b22",
          },
          thumb: {
            backgroundColor: "#FFFFFF",
          },
        },
      },
    },
  });

  const getQuestion = () => {
    return state.survey.questions[state.index];
  };
  async function selectOption(selectedIndex, isSlider = false) {
    let question = getQuestion(state.survey);
    if (isSlider) {
      // const value = selectedIndex * 0.1; //convert scale to 0-10
      question.selection = selectedIndex;
    } else {
      question.selection = question.options[selectedIndex];
    }

    let newSurvey = state.survey;
    newSurvey.questions[state.index] = question;
    console.log("new survey ", newSurvey);
    let nextIndex = state.index + 1;
    if (newSurvey.questions.length == nextIndex) {
      //is last question

      submitSurvey(newSurvey);
      return;
    }
    navigate("/pickerView", {
      state: { survey: state.survey, index: nextIndex, isSurgeon: state.isSurgeon },
    });
    if (isSlider) {
      //window.location.reload(false);
    }
  }
  const submitSurvey = (survey) => {
    const patient = JSON.parse(localStorage.getItem("patient"));
    console.log("patient ", patient);
    const selections = survey.questions.map((item) => item.selection);
    let obj;
    if (state.isSurgeon) {
      obj = {
        patientId: localStorage.getItem("isKSSFUllIdPatient"),
        surveyName: survey.title,
        surveyFile: survey.test,
        selections: selections,
      };
    } else {
      obj = {
        patientId: patient._id,
        surveyName: survey.title,
        surveyFile: survey.test,
        selections: selections,
      };
    }
    console.log("obj ", obj);
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    fetch(`${API_URL}addResult`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        console.log("data ", res);
        localStorage.setItem("surveyDone", "true");
        console.log("setting local storage", localStorage.getItem("surveyDone"));
        if (state.isSurgeon) {
          navigate(-13, {state: {isFullKssTest: true}});
        } else {
          navigate("/patientHome", {
            state: { patient },
          });
        }
      });
  };

  const marks = [
    {
      value: 0,
      label: getQuestion().options[0],
    },
    {
      value: 10,
      label: getQuestion().options[getQuestion().options.length - 1],
    },
  ];
  const getSliderMinValue = () => {
    return 0;
  };
  const getSliderMaxValue = () => {
    return state.survey.hasSteps ? 3 : 10;
  };
  const getSliderStepValue = () => {
    return null;
  };
  const renderSliderQuestion = () => {
    if (state.survey.type === "slider") {
      setTimeout(() => {
        let slider = document.querySelector('.MuiSlider-root');
        slider.addEventListener('mouseenter', function () {
          document.querySelector('.blockButton').style.display = 'none';
          window.setactiveitembuttonalder = true;
        });
      }, 500);

      return (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, transform: "translateY(50px)" }}
            animate={{ opacity: 1, transform: "translateY(0px)" }}
            exit={{ opacity: 0, transform: "translateY(-50px)" }}
          >
            <div className="loginView-main-div">
              <h4
                style={{
                  textAlign: "left",
                  padding: "15px 0px",
                  fontSize: "20px",
                  margin: "70px 0px",
                }}
              >
                {getQuestion().title}
              </h4>
              <ThemeProvider theme={theme}>
                <Slider
                  aria-label=""
                  onChange={(e, val) => {
                    sliderValue = val;
                  }}
                  min={getSliderMinValue()}
                  max={getSliderMaxValue()}
                  steps={getSliderStepValue()}
                />
              </ThemeProvider>
              <div style={{ display: "flex", gap: "5px", justifyContent: "space-between", width: '100%'}}>
                <TinyText style={{ marginRight: "auto" }}>
                  {marks[0].label}
                </TinyText>
                <TinyText style={{ marginLeft: "auto" }}>
                  {marks[1].label}
                </TinyText>
              </div>
              <div
                style={{ position: 'relative' }}
                onClick={() => {
                  document.querySelector('.blockButton').style.display = 'block';
                  document.querySelector('.MuiSlider-track').style.width = '0%';
                  document.querySelector('.MuiSlider-thumb').style.left = '0%';
                  if (window.setactiveitembuttonalder) {
                    selectOption(sliderValue, true);
                  }
                  sliderValue = 0;
                  window.setactiveitembuttonalder = false;
                }}
              >
                <button
                  type="button"
                  className="button"
                  style={{
                    margin: "70px 0px",
                  }}
                >
                  Siguiente
                </button>
                <div className="blockButton">

                </div>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      );
    } else {
      function formatearName(item) {
        for (let index = 0; index < 100; index++) {
          item = item.replace("("+index+")", "");
          item = item.replace("(-"+index+")", "");
        }
        return item;
      }
      return (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, transform: "translateY(50px)" }}
            animate={{ opacity: 1, transform: "translateY(0px)" }}
            exit={{ opacity: 0, transform: "translateY(-50px)" }}
          >
            <h4
              style={{
                textAlign: "left",
                padding: "15px 0px",
                fontSize: "20px",
              }}
            >
              {getQuestion().title.replace('(A)-',' ').replace('(D)-', ' ')}
            </h4>
            <div className={styles.listContainer}>
              {getQuestion().options.map((item, index) => (
                <span
                  className={styles.listItem}
                  style={{
                    borderBottom:
                      getQuestion()?.options?.length - 1 !== index &&
                      "1px solid #22222240",
                  }}
                  onClick={() => {
                    selectOption(index);
                  }}
                >
                  { formatearName(item) } 
                </span>
              ))}
            </div>
          </motion.div>
        </AnimatePresence>
      );
    }
  };

  return (
    <div
      style={{ padding: "0px !important" }}
      className={styles.pickerOptionsViewMainDiv}
    >
      {renderSliderQuestion()}
    </div>
  );
};

export default PickerView;
