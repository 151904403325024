import React, { PureComponent, useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
// import { MDBBtn } from "mdb-react-ui-kit";
import { API_URL } from "../../Utils/constants";
import "../main.css";
import "../surgeon/loginView.css";
import "./patientHome.css";
import PatientInformation from "./PatientInformation";

const PatientHome = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [patient, setPatient] = useState({});
  const getNextSurvey = () => {
    const completedSurveys = (state.patient.completedSurveys ?? []).map(
      (i) => i.fileName
    );
    const patientSureveyTitles = (patient.questionFiles ?? []);
    console.log({patientSureveyTitles});
    const patientFiles = (patient.allFiles ?? []).filter(
      (item) => patientSureveyTitles.includes(item.title)
    );
    const remainingSurveys = patientFiles.filter(
      (item) => !completedSurveys.includes(item.file)
    )
    const remainingSurveysP = remainingSurveys.filter(system => system.title !== "KSS");
    console.log("getNextSurvey ", remainingSurveysP, completedSurveys, patientFiles);
    
    if (remainingSurveysP.length > 0) {
      navigate("/surveyHome", { state: { name: remainingSurveysP[0].title } });
    }
    return;
  };
  useEffect(() => {
    console.log("state ", state);
    setPatient(JSON.parse(localStorage.getItem("patient")));
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ password: state.patient.password }),
    };
    fetch(`${API_URL}patient/login`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (!res._id) {
          return;
        }
        let patient = res;
        localStorage.setItem("patient", JSON.stringify(patient));
        setPatient(patient);
        console.log("localStorage ", localStorage);
        console.log("data ", res);
        console.log("localStorage ", localStorage);
        navigate("/patientHome", {
          state: { patient },
        });
        if (localStorage.getItem("surveyDone") != null) {
          localStorage.removeItem("surveyDone");
          localStorage.setItem("loadNext", "true");
          //window.location.reload(false);
        } 
      });
  }, []);
  const getRemainingSurveys = () => {
    const completedSurveys = (patient.completedSurveys ?? []).map(
      (i) => i.fileName
    );
    const patientSureveyTitles = (patient.questionFiles ?? []);
    const patientFiles = (patient.allFiles ?? []).filter(
      (item) => patientSureveyTitles.includes(item.title)
    );
    const remainingSurveys = patientFiles.filter(
      (item) => !completedSurveys.includes(item.file)
    )
    const remainingSurveysP = remainingSurveys.filter(system => system.title !== "KSS");

    console.log('remainingSurveys', remainingSurveysP)

    return remainingSurveysP;
  };

  //const createdAt = new Date(state?.patient.createdAt);

  const getRenderedData = () => {
    if (getRemainingSurveys().length < 1) {
      return (
        <div>
          <h1>
            Ha completado todas las preguntas. Muchas gracias por su tiempo.
          </h1>
        </div>
      );
    } else {
      //console.log(state.patient.questionFiles.length, getRemainingSurveys().length, getRemainingSurveys)
      if (state.patient.questionFiles.length == getRemainingSurveys().length) {
        return (
          <PatientInformation getNextSurvey = {getNextSurvey}></PatientInformation>
        );
      } else {
        // setTimeout(function () {
        //   getNextSurvey();
        // },3500);
        return(
          <div>
            <div style={{textAlign: "center"}}>
              <h1>Por Favor continúe con el siguiente Test </h1>
            </div>
            <div style={{display:"flex" , justifyContent: "center"}} onClick={() => { getNextSurvey(); }}>
              <button className="button" id="botonNextAng" style={{ marginTop: "60px", fontSize: "16px" }}>
                Continuar
              </button>
            </div>
            <div className="progress-bar">
              <span></span> 
            </div>
          </div>
        );
      }
    }
  };

  return getRenderedData();
};

export default PatientHome;
