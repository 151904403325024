import React from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export default function BackButton() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        gap: "1px",
      }}
      onClick={() => navigate(-1)}
    >
      <HiOutlineChevronLeft size={20} color="#399b22" />
      <span style={{ color: "#399b22", fontSize: "18px", fontWeight: "500" }}>
        BACK
      </span>
    </div>
  );
}
