import React, { PureComponent, useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
// import { MDBBtn } from "mdb-react-ui-kit";
import { API_URL } from "../../Utils/constants";
import styles from "./patientListView.module.css";
import { BsArrowRight } from "react-icons/bs";
import BackButton from "../backButton";

const PatientListView = (props) => {
  const { state } = useLocation();
  const [results, setResults] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setResults(state.results);
  }, []);
  const selectPatient = (patient) => {
    console.log("p ", patient);
    navigate("/patientScoreView", { state: patient });
  };
  const getRenderedData = () => {
    if (results.length < 1) {
      return (
        <div>
          <h1>No results</h1>
        </div>
      );
    } else {
      return (
        <div style={{ width: "400px" }}>
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="mb-4"
          >
            <BackButton />
            <h4 className=" text-center" style={{ marginLeft: "80px" }}>
              RESULTADOS
            </h4>
          </div>
          <div className={styles.mainContainer}>
            {results.map((i, ind) => {
              console.log(i, "i");
              return (
                <div
                  key={ind}
                  className={styles.resultBox}
                  onClick={() => {
                    selectPatient(i);
                  }}
                >
                  <span style={{ fontSize: "17px" }}>
                    {i.apellido ? i.apellido.toUpperCase() : "No Name"}
                  </span>
                  <BsArrowRight size={18} color="#222222" />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  };

  return getRenderedData();
};

export default PatientListView;
