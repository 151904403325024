import React, { PureComponent, useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
// import { MDBBtn } from "mdb-react-ui-kit";
import { API_URL } from "../../Utils/constants";
import styles from "./patientScore.module.css";
import BackButton from "../backButton";
import Select, { StylesConfig } from 'react-select';
import OptionsModal from "../optionModal";


const PatientScoreView = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [diagnostics, setDiagnostics] = useState([""]);
  const [diagnostic, setDiagnostic] = useState("");
  const [treatments, setTreamtns] = useState([""]);
  const [treatment, setTreamtn] = useState("");
  const [staticTreatments, setStaticTreatments] = useState(treatments);
  const createdAt = new Date(state?.createdAt);
  const [openOpt, setOpenOpt] = useState(false);
  const [openOptTwo, setOpenOptTwo] = useState(false);
  const [staticDiagnostics, setStaticDiagnostics] = useState(diagnostics);
  const handleDiagnostics = (diag) => {
    setDiagnostic(diag);
    setOpenOpt(false);
  };
  const handleTreat = (treat) => {
    setTreamtn(treat);
    setOpenOptTwo(false);
  };

  useEffect(() => {
    fetch(`${API_URL}surveys/getLadoFormData`)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result) {
            setDiagnostics(result.diagnostic);
            setTreamtns(result.tratamentios);
          }
        },
        (error) => {
          console.log("error ", error);
        }
      );
  }, []);

  console.log('AAAA', state);

  localStorage.setItem("pacienteHijo", state?._id);
  localStorage.setItem("pacienteHijoApellido", state?.apellido);
  localStorage.setItem("pacienteHijoHistoria", state?.historia);
  localStorage.setItem("pacienteHijoLado", state?.lado);
  localStorage.setItem("pacienteHijoName", state?.number);
  localStorage.setItem("pacienteHijoHospital", state?.hospital);
  localStorage.setItem("pacienteHijoDiagnosticos", state?.diagnostic);
  localStorage.setItem("pacienteHijoTratamientos", state?.tratamentios);

  return (
    <div className="loginView-main-div" style={{ position: "relative" }}>
      <OptionsModal
        options={treatments}
        open={openOpt}
        setOpen={setOpenOpt}
        onChange={handleDiagnostics}
        setOptions={setDiagnostics}
        staticData={staticDiagnostics}
      />
      <OptionsModal
        options={diagnostics}
        open={openOptTwo}
        setOpen={setOpenOptTwo}
        onChange={handleTreat}
        setOptions={setTreamtns}
        staticData={staticTreatments}
      />
      <div style={{ position: "absolute", top: "-28px", left: "0" }}>
        <BackButton />
      </div>
      <div className={styles.mainContainer}>
        <div
          className={styles.nuevo}
          onClick={() => navigate("/scoreSelectView")}
          style={{ cursor: "pointer" }}
        >
          <span
            style={{ color: "#399b22", fontWeight: "600", fontSize: "17px" }}
          >
            Nuevo
          </span>
          <span style={{ color: "black", fontWeight: "500", fontSize: "16px" }}>
            {state?.apellido.toUpperCase()}
          </span>
        </div>
        <p
          style={{
            color: "#868286",
            fontSize: "14px",
            marginTop: "16px",
            marginLeft: "5px",
            fontWeight: "500",
          }}
        >
        </p>

        <div className={styles.doubleBox}>
          <div style={{ borderBottom: "1px solid #22222240" }}>
            <span>DIAGNÓSTICOS</span>
            <span
              style={{ color: "#399b22", fontWeight: "500", maxWidth: "200px" }}
            >


            <div
              className={`d-flex  justify-content-between align-items-center ${styles.inputContainer}  mb-3`}
            >
              <span
                style={{
                  color: "#399b22",
                  fontWeight: "500",
                  cursor: "pointer",
                  maxWidth: "200px",
                }}
                onClick={() => setOpenOpt(true)}
              >
                {!diagnostic ? state?.diagnostic === null || state?.diagnostic[0] == 'null' ? 'Select' : typeof state?.diagnostic == "string"
                ? state?.diagnostic
                : state?.diagnostic?.map((val) => `${val} \n`) : diagnostic}
              </span>
            </div>
            </span>
          </div>
          <div>
            <span>TRATAMIENTOS</span>
            <span
              style={{ color: "#399b22", fontWeight: "500", maxWidth: "200px" }}
            >
            <div
              className={`d-flex  justify-content-between align-items-center ${styles.inputContainer}  mb-3`}
            >
              <span
                style={{
                  color: "#399b22",
                  fontWeight: "500",
                  cursor: "pointer",
                  maxWidth: "200px",
                }}
                onClick={() => setOpenOptTwo(true)}
              >
               {!treatment ? state?.tratamentios == 'null' ? 'Select' :  typeof state?.tratamentios == "string"
                ? state?.tratamentios
                : state?.tratamentios?.map((val) => `${val} \n`) : treatment}
              </span>
            </div>
            </span>
          </div>
        </div>

        <div className={styles.doubleBox} style={{ margin: "25px auto" }}>
        <div style={{ borderBottom: "1px solid #22222240" }}>
            <span>Lado</span>
            <span style={{ fontWeight: "600" }}>{state?.lado}</span>
          </div>
          <div style={{ borderBottom: "1px solid #22222240" }}>
            <span>Codigo</span>
            <span style={{ fontWeight: "600" }}>{state?.password}</span>
          </div>
          <div>
            <span>Fecha</span>
            <span style={{ fontWeight: "600" }}>{`${createdAt?.getDate()}-${
              createdAt?.getMonth() + 1
            }-${createdAt?.getFullYear()}`}</span>
          </div>
        </div>

        <p
          style={{
            color: "#868286",
            fontSize: "16px",
            marginTop: "16px",
            marginLeft: "5px",
            fontWeight: "500",
          }}
        >
          
        </p>

        {state.surveyScores.map((i) => {
          return (
            <>
              <div className={styles.doubleBox} style={{ margin: "25px auto" }}>
                <div style={{ borderBottom: "1px solid #22222240" }}>
                  <span style={{ fontWeight: "600" }}>{i.surveyName}</span>
                </div>

                {i.scoreArray.map((s) => {
                  return (
                    <div>
                      <span>{s.title}</span>
                      <span style={{ fontWeight: "600" }}>{s.value}</span>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>

      <hr/>
      
      {
        state.childPatients.length > 0 ?
          <div className={styles.mainContainer}>
            {state.childPatients.map((i) => {
              return (
                <>
                  <div className={styles.doubleBox}>
                    <div style={{ borderBottom: "1px solid #22222240" }}>
                      <span>DIAGNÓSTICOS</span>
                      <span style={{ color: "#399b22", fontWeight: "500", maxWidth: "200px" }}>
                      <div className={`d-flex  justify-content-between align-items-center ${styles.inputContainer}  mb-3`}>
                        <span style={{
                            color: "#399b22",
                            fontWeight: "500",
                            cursor: "pointer",
                            maxWidth: "200px",
                          }}
                        >
                          {!diagnostic ? i?.diagnostic === null || i?.diagnostic[0] == 'null' ? 'Select' : typeof i?.diagnostic == "string"
                          ? i?.diagnostic
                          : i?.diagnostic?.map((val) => `${val}  \n`) : diagnostic}
                        </span>
                      </div>
                      </span>
                    </div>
                    <div>
                      <span>TRATAMIENTOS</span>
                      <span style={{ color: "#399b22", fontWeight: "500", maxWidth: "200px" }}>
                      <div className={`d-flex  justify-content-between align-items-center ${styles.inputContainer}  mb-3`}>
                        <span
                          style={{
                            color: "#399b22",
                            fontWeight: "500",
                            cursor: "pointer",
                            maxWidth: "200px",
                          }}
                        >
                        {!treatment ? i?.tratamentios == 'null' ? 'Select' :  typeof i?.tratamentios == "string"
                          ? i?.tratamentios
                          : i?.tratamentios?.map((val) => `${val}  \n`) : treatment}
                        </span>
                      </div>
                      </span>
                    </div>
                  </div>
                  <div className={styles.doubleBox} style={{ margin: "25px auto" }}>
                    <div style={{ borderBottom: "1px solid #22222240" }}>
                      <span>Lado</span>
                      <span style={{ fontWeight: "600" }}>{i?.lado}</span>
                    </div>
                    <div style={{ borderBottom: "1px solid #22222240" }}>
                      <span>Codigo</span>
                      <span style={{ fontWeight: "600" }}>{i?.password}</span>
                    </div>
                    <div>
                      <span>Fecha</span>
                      <span style={{ fontWeight: "600" }}>{`${createdAt?.getDate()}-${
                        createdAt?.getMonth() + 1
                      }-${createdAt?.getFullYear()}`}</span>
                    </div>
                  </div>

                  {i.surveyScores.map((i) => {
                    return (
                      <>
                        <div className={styles.doubleBox} style={{ margin: "25px auto" }}>
                          <div style={{ borderBottom: "1px solid #22222240" }}>
                            <span style={{ fontWeight: "600" }}>{i.surveyName}</span>
                          </div>

                          {i.scoreArray.map((s) => {
                            return (
                              <div>
                                <span>{s.title}</span>
                                <span style={{ fontWeight: "600" }}>{s.value}</span>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    );
                  })}
                </>
              );
            })} 
          </div>
        : ''
      }

    </div>
  );
};

export default PatientScoreView;
