import React, { PureComponent } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { API_URL } from "../../Utils/constants";
import "../main.css";
import "./loginView.css";
import { BsFillPersonFill } from "react-icons/bs";
import CustomTextInput from "../FormUI/TextInput";

const SurgeonLogin = (props) => {
  const navigate = useNavigate();
  const ON_SUBMIT = async (values, { setSubmitting, resetForm }) => {
    console.log("value are ", values);
    setSubmitting(true);
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(values),
    };
    fetch(`${API_URL}surgeon/login`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        console.log("data ", res);
        if (!res._id) {
          // console.log(res.message)
          alert(res.message);
          return;
        }
        let surgeon = res;
        localStorage.setItem("surgeon", JSON.stringify(surgeon));
        navigate("/suregonHome");
      });
    setTimeout(() => {
      resetForm();
      setSubmitting(false);
    }, 300);
  };
  return (
    <div className="loginView-main-div">
      <div className="profile-default">
        <BsFillPersonFill />
      </div>
      {/* <h4 className="mb-5">CIRUJANO</h4> */}
      <Formik
        initialValues={{ username: "", password: "" }}
        onSubmit={ON_SUBMIT}
      >
        <Form>
          <div className="login-form">
            <CustomTextInput
              name="username"
              type="text"
              id="username"
              // className="form-field"
              placeholder="Username"
            />
            <CustomTextInput
              name="password"
              type="password"
              id="password"
              // className="form-field"
              placeholder="Código"
            />
            <button
              type="submit"
              className="d-flex button mx-auto text-center my-3"
            >
              Siguiente
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default SurgeonLogin;
