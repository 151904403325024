import React, { PureComponent, useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
// import { MDBBtn } from "mdb-react-ui-kit";
import { API_URL } from "../../Utils/constants";
import "../main.css";
import "../surgeon/loginView.css";
import "./surveyHome.css";

const SurveyHome = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [survey, setSurvey] = useState({});
  const getQuestion = () => {
    return { survey: survey };
  };
  useEffect(() => {
    console.log("state ", state, `${API_URL}getSurvey/${state.name}`);
    fetch(`${API_URL}getSurvey/${state.name}`)
      .then((res) => res.json())
      .then((res) => {
        console.log("data ", res);
        setSurvey(res);
      });
  }, []);
  return (
    <div className="loginView-main-div">
      <h1 className="surveyHomeTitle">{survey.title}</h1>
      <h3
        style={{
          fontSize: "24px",
          color: "black",
          padding: "10px 0px",
          textAlign: "justify",
          fontWeight: "500",
          lineHeight: 1.5,
        }}
      >
        {survey.subtitle}
      </h3>
      <div>
        <p
          style={{
            fontSize: "19px",
            color: "black",
            padding: "10px 0px",
            textAlign: "justify",
            fontWeight: "500",
          }}
        >
          {survey.description}
        </p>
      </div>
      <table></table>
      <div className="form-outline mb-4">
        <div
          onClick={() => {
            navigate("/pickerView", { state: { survey: survey, index: 0, isSurgeon: state.isSurgeon } });
          }}
        >
          <button className="button" style={{ fontSize: "16px" }}>
            Empezar
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveyHome;
