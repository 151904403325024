import React, { useEffect, useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import { Row, Col } from "react-bootstrap";
import * as Yup from "yup";
import CustomTextInput from "../FormUI/TextInput/index";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import CustomSelectInput from "../FormUI/SelectInput/index";
import { useNavigate, useLocation } from "react-router-dom";
import { API_URL } from "../../Utils/constants";
import "../surgeon/loginView.css";
import "../main.css";
import { BsArrowRightShort } from "react-icons/bs";
import CustomInput2 from "../FormUI/CustumInput2";
import styles from "./newPatientTwo.module.css";
import { motion } from "framer-motion";
import OptionsModal from "../optionModal";
import BackButton from "../backButton";

window.arrayTrata = [];
window.arrayDiag = [];

const NewPatientTwo = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [diagnostics, setDiagnostics] = useState([""]);
  const [staticDiagnostics, setStaticDiagnostics] = useState(diagnostics);
  const [diagnostic, setDiagnostic] = useState("");

  const [radioValue, setRadioValue] = useState("");

  const radios = [
    { name: "DER", value: "DER" },
    { name: "IZQ", value: "IZQ" },
  ];
  // const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);

  // const [open, setOpen] = useState(false);
  const [openOpt, setOpenOpt] = useState(false);
  const [openOptTwo, setOpenOptTwo] = useState(false);

  const [treatments, setTreamtns] = useState([""]);
  const [staticTreatments, setStaticTreatments] = useState(treatments);

  const [treatment, setTreamtn] = useState("");
  const INITIAL_STATE = {
    diagnostics: diagnostics,
    tratamientos: treatments,
    fecha: "",
    lado: "DER",
  };
  const FORM_VALIDATION = Yup.object({
    //diagnostics: Yup.string().min(3, "must be at least 3 characters"),
    //tratamientos: Yup.string().min(3, "must be at least 3 characters"),
    fecha: Yup.string().min(3, "must be at least 3 characters"),
    //lado: Yup.string().oneOf(["DER", "IZQ"]).required("Required"),
  });
  const ON_SUBMIT = async (values, { setSubmitting, resetForm }) => {
    console.error(diagnostic, treatment, values)

    document.getElementById('validDiagnosticos').style.display = 'none';
    document.getElementById('validTratamientos').style.display = 'none';
    //document.getElementById('validFecha').style.display = 'none';

    let statusValid = true;
    // if (diagnostic == '') {
    //   statusValid = false;
    //   document.getElementById('validDiagnosticos').style.display = 'flex';
    // }
    // if (treatment == '') {
    //   statusValid = false;
    //   document.getElementById('validTratamientos').style.display = 'flex';
    // }
    // if (values.fecha == '') {
    //   statusValid = false;
    //   document.getElementById('validFecha').style.display = 'flex';
    // }

    if (radioValue == '') {
      statusValid = false;
      document.getElementById('validFecha').style.display = 'flex';
    }

    if (statusValid) {
      let obj = {
        ...state,
        ...values,
        lado: radioValue,
        diagnostics: diagnostic,
        tratamientos: treatment,
      };
      console.log("value are ", obj);
      setTimeout(() => {
        resetForm();
        setSubmitting(false);
        navigate("/scoreSelectView", { state: obj });
      }, 300);
    }
  };

  const handleDiagnostics = (diag) => {
    let result = '';
    const index = window.arrayDiag.indexOf(diag);
    if (index > -1) {
      window.arrayDiag.splice(index, 1);
    } else {
      window.arrayDiag.push(diag);
      let ii = 0;
      window.arrayDiag.forEach(element => {
        if (ii > 0) {
          result = result + ",";
        }
        result = result + element;
        ii++;
      });
    }
    setDiagnostic(result);
    //setOpenOpt(false);
  };

  
  const handleTreat = (treat) => {
    let result = '';
    const index = window.arrayTrata.indexOf(treat);
    if (index > -1) {
      window.arrayTrata.splice(index, 1);
    } else {
      window.arrayTrata.push(treat);
      let ii = 0;
      window.arrayTrata.forEach(element => {
        if (ii > 0) {
          result = result + ",";
        }
        result = result + element;
        ii++;
      });
    }

    setTreamtn(result);
    //setOpenOptTwo(false);
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${API_URL}surveys/getLadoFormData`)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result) {
            setDiagnostics(result.tratamentios);
            setTreamtns(result.diagnostic);
            setLoading(false);
          }
        },
        (error) => {
          console.log("error ", error);
          setLoading(false);
        }
      );
  }, []);

  useEffect(() => {
    if (diagnostics?.length > 0) {
      setStaticDiagnostics(diagnostics);
    }
  }, [loading]);

  useEffect(() => {
    if (treatments?.length > 0) {
      setStaticTreatments(treatments);
    }
  }, [loading]);

  return (
    <div class="text-left">
      <div style={{ display: "flex", alignItems: "center" }} className="mb-4">
        <BackButton />
        <h4 className=" text-center" style={{ marginLeft: "100px" }}>
          LADO
        </h4>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...INITIAL_STATE }}
        validationSchema={FORM_VALIDATION}
        onSubmit={ON_SUBMIT}
      >
        {(props) => (
          <Form>
            <div
              className={`d-flex  justify-content-between align-items-center ${styles.inputContainer}  mb-3`}
            >
              <label htmlFor={props.id || props.name}>LADO</label>
              <div className={styles.toggleButtonContainer}>
                <motion.div
                  className={styles.selectedToggleButton}
                  style={{
                    left: radioValue === "DER" ? "5px" :  radioValue === "" ? '' : "50px",
                  }}
                ></motion.div>
                {radios?.map((val) => {
                  return (
                    <p onClick={() => setRadioValue(val?.value)}>{val?.name}</p>
                  );
                })}
              </div>
            </div>
            <div id="validFecha" style={{ display: "none", paddingBottom: '15px'}}>
              Debe elegir un lado
            </div>
            <div className={`d-flex  justify-content-between align-items-center ${styles.inputContainer}  mb-3`}>
              <span>DIAGNÓSTICOS</span>
              <span
                style={{
                  color: "#399b22",
                  fontWeight: "500",
                  cursor: "pointer",
                  maxWidth: "200px",
                }}
                onClick={() => setOpenOpt(true)}
              >
                {!diagnostic ? "Select" : diagnostic.replace(/,/g, "\n")}
              </span>
            </div>

            <div id="validDiagnosticos" style={{ display: "none"}}>
              Debe elegir un diagnostico
            </div>

            <OptionsModal
              options={diagnostics}
              open={openOpt}
              setOpen={setOpenOpt}
              onChange={handleDiagnostics}
              setOptions={setDiagnostics}
              staticData={staticDiagnostics}
              type={1}
            />

            <div className={`d-flex  justify-content-between align-items-center ${styles.inputContainer}  mb-3`}>
              <span>TRATAMIENTOS</span>
              <span
                style={{
                  color: "#399b22",
                  fontWeight: "500",
                  cursor: "pointer",
                  maxWidth: "200px",
                }}
                onClick={() => setOpenOptTwo(true)}
              >
                {!treatment ? "Select" : treatment.replace(/,/g, "\n")}
              </span>
            </div>

            <OptionsModal
              options={treatments}
              open={openOptTwo}
              setOpen={setOpenOptTwo}
              onChange={handleTreat}
              setOptions={setTreamtns}
              staticData={staticTreatments}
              type={2}
            />

            {/* <CustomInput2
              label="DIAGNÓSTICOS"
              name="diagnostics"
              // className="formSelect"
              onChangeHandler={onChangeHandler}
            >
              <option value="">ALARGAMIENTO TENDÓN ROTULIANO</option>
              {diagnostics.map((value) => {
                return <option>{value}</option>;
              })}
            </CustomInput2> */}

            {/* <div className="dropdown-section formSelect" >
              <label htmlFor={props.id || props.name}>DIAGNÓSTICOS</label>
              <div className="dropdown" name="diagnostics">
                <div className="dropdown-selected-option" onClick={() => setOpen(!open)}>
                  {selectedOption || "ALARGAMIENTO TENDÓN ROTULIANO"}

                </div>
                {open && (
                  <div className="dropdown-options">
                    {diagnostics.map((option, ind) => (
                      <div
                        key={ind}
                        className={`dropdown-option ${option === selectedOption ? "checked" : ""}`}
                        onClick={() => handleOptionSelect(option)}
                      >
                        {option === selectedOption && <span className="checkmark">&#10003;</span>}
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div> */}

            {/* <CustomSelectInput
              className="formSelect"
              label="TRATAMIENTOS"
              name="tratamientos"
            >
              <option value="">Select</option>
              {treatments.map((value) => {
                return <option>{value}</option>;
              })}
            </CustomSelectInput> */}

            {/* <CustomInput2 label="TRATAMIENTOS" name="tratamientos">
              <option value="">AFLOJAMIENTO ASÉPTICO</option>
              {treatments.map((value) => {
                return <option>{value}</option>;
              })}
            </CustomInput2> */}

            <div id="validTratamientos" style={{ display: "none"}}>
              Debe elegir un tratamiento
            </div>

            {/* <CustomTextInput
              label="FECHA"
              type="date"
              // className="form-field"
              name="fecha"
              placeholder="FECHA"
            />

            <div id="validFecha" style={{ display: "none"}}>
              Debe elegir una fecha
            </div> */}

            <div className="form-group d-flex my-3">
              <button type="submit" class="arrowButton">
                <div>{props.isSubmitting ? "Wait..." : "Siguiente"}</div>
                <div className="buttonArrow">
                  <BsArrowRightShort />
                </div>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewPatientTwo;
