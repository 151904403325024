import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styles from "./optionModal.module.css";
import { FiSearch } from "react-icons/fi";
import { BsArrowRightShort, BsCheck } from "react-icons/bs";

export default function OptionsModal({
  open,
  setOpen,
  options,
  onChange,
  setOptions,
  staticData,
  type = false
}) {
  const handleClose = () => setOpen(false);

  const [inputValue, setInputValue] = React.useState("");

  // Effect that runs when inputValue changes
  React.useEffect(() => {
    // If the input value is empty, reset the displayed data to the original data
    if (inputValue === "") {
      setOptions(options);
    }
    // Otherwise, filter the original data based on the input value
    else {
      const filteredData = staticData.filter((item) =>
        item.toLowerCase().includes(inputValue.toLowerCase())
      );
      setOptions(filteredData);
    }
  }, [inputValue]);

  console.log(staticData, "static");

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div className={styles.mainContainer}>
            <div className={styles.searchContainer}>
              <p onClick={handleClose}>GUARDAR</p>
              <div className={styles.inputContainer}>
                <FiSearch size={20} color="black" />
                <input
                  type="text"
                  onChange={(e) => setInputValue(e.target.value)}
                  value={inputValue}
                />
              </div>
            </div>
            <div className={styles.optionContainer}>
              {options?.map((val, i) => {
                let mostrarChack = false;
                if (type == 1) {
                  window.arrayDiag.forEach(element => {
                    if (element == val) {
                      mostrarChack = true;
                    }
                  });
                } else if (type == 2) {
                  window.arrayTrata.forEach(element => {
                    if (element == val) {
                      mostrarChack = true;
                    }
                  });
                }
                return (
                  <div
                    onClick={() => onChange(val)}
                    key={i}
                    style={{
                      borderBottom:
                        options?.length - 1 !== i && "1px solid #22222230",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                    }}
                  >
                    <span>
                      {val}
                    </span>
                    <span>
                      {mostrarChack ? <BsCheck color={"#76b568"} size={24} /> : ''}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
