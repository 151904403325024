import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import CustomTextInput from "../FormUI/TextInput/index";
import CustomSelectInput from "../FormUI/SelectInput/index";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { API_URL } from "../../Utils/constants";
import { BsArrowRightShort, BsCheck } from "react-icons/bs";
import { HiChevronDown } from "react-icons/hi";
import styles from "./scoreSelectView.module.css";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { FiSearch } from "react-icons/fi";
import BackButton from "../backButton";
import moment from 'moment';

const ScoreSelectView = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const formikProps = useFormikContext();
  let selectedOption = [];
  const [nameActiveOptionClickList, setNameActiveOptionClickList] = useState();
  const [ligaments, setLigaments] = useState([""]);
  const ligamentsInputRef = useRef();
  const [femoropates, setFemoropates] = useState([""]);
  const femoropatesInputRef = useRef();
  const [meniscs, setMeniscs] = useState([""]);
  const meniscsInputRef = useRef();
  const [osteotomias, setSteotomias] = useState([""]);
  const osteotomiasInputRef = useRef();
  const [protesicos, setProtesicos] = useState([""]);
  const protesicosInputRef = useRef();
  const [foca, setFoca] = useState([""]);
  const focaInputRef = useRef();
  const [manual, setManual] = useState([""]);
  const manualInputRef = useRef();
  const [selectedTest, setSelectedTest] = useState([""]);
  const [surgeon, setSurgeon] = useState();
  const namesArray = [
    "ligaments",
    "femoropates",
    "meniscs",
    "osteotomias",
    "protesico",
    "FOCA",
    "manual",
  ];
  const refArray = [
    ligamentsInputRef,
    femoropatesInputRef,
    meniscsInputRef,
    osteotomiasInputRef,
    protesicosInputRef,
    focaInputRef,
    manualInputRef,
  ];
  let activeFieldName = "";
  const handleSelectChange = (selected, e) => {
    if (e.name === activeFieldName || activeFieldName === "") {
      selectedOption =
        selected.map((item) => {
          
          return item.value;
        }) || [];
      setSelectedTest(selectedOption.join(","));
      activeFieldName = e.name;
    }

    let index = namesArray.indexOf(e.name);
    let names = [...namesArray];
    let references = [...refArray];
    references.splice(index, 1);
    names.splice(index, 1);

    if (selected.length == 0) return; //if cleared item
    names.forEach((name) => {
      references[names.indexOf(name)].current.clearValue();
    });
  };
  const INITIAL_STATE = {
    fecha: "",
    selectedTest: "",
  };
  const FORM_VALIDATION = Yup.object({
    selectedTest: Yup.string().min(1, "Please select at least one test"),
    fecha: Yup.string().min(3, "Please select date"),
  });

  const dateString = moment().format('yyyy-MM-DD');

  console.log(dateString);

  const ON_SUBMIT = async (values, { setSubmitting, resetForm }) => {
    
    let isValid = true;

    document.getElementById('mensajeValidTest').style.display = 'none';
    document.getElementById('mensajeValidFecha').style.display = 'none';

    if (selectedTest[0] == "") {
      isValid = false;
      document.getElementById('mensajeValidTest').style.display = 'flex';
    }

    if (values.fecha == '') {
      values.fecha = dateString;
      // isValid = false;
      // document.getElementById('mensajeValidFecha').style.display = 'flex';
    }

    if (isValid) {
      setSubmitting(true);
      setTimeout(()=>{
        let obj = {
          ...state,
        };

      let vari = selectedTest;
      if (vari.includes("Psicológicos")) {
        vari = vari.filter((val) => val !== "Psicológicos");
        vari.push('HAD');
        vari.push('PCS');
        vari.push('TSK');
      }
        obj.surgeonId = surgeon._id;
        obj.questionFiles = vari;

        if (localStorage.getItem("pacienteHijo") !== null) {
          obj.oldPatientId = localStorage.getItem("pacienteHijo");
          obj.apellido = localStorage.getItem("pacienteHijoApellido");
          obj.historia = localStorage.getItem("pacienteHijoHistoria");
          obj.name = localStorage.getItem("pacienteHijoName");
          obj.hospital = localStorage.getItem("pacienteHijoHospital");

          obj.tratamientos = localStorage.getItem("pacienteHijoTratamientos");
          obj.diagnostics = localStorage.getItem("pacienteHijoDiagnosticos");
        
        }
        
        const requestOptions = {
          method: "POST",
          body: JSON.stringify(obj),
        };
        fetch(`${API_URL}addPatient`, requestOptions)
          .then((response) => response.json())
          .then((res) => {
            let patient = res.patient;
            
            navigate("/confirmView", {
              state: { patient },
            });
          }
        );
        setTimeout(() => {
          resetForm();
          setSubmitting(false);
        }, 300);
      },750);
    }
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  // console.log(ligaments)
  // console.log(femoropates)
  // console.log(meniscs)
  // console.log(osteotomias)
  // console.log(protesicos)
  // console.log(manual)
  
  const nameValues = [
    { name: "LIGAMENTOS", values: ["IKDC", "Marx", "ACL RSI", "Tegner"] },
    { name: "FEMOROPATELAR", values: ["Kujala", "Tegner", "IKDC", "Marx"] },
    { name: "MENISCO", values: ["IKDC", "Marx", "WOMET", "Tegner" ] },
    { name: "OSTEOTOMIA / CARTÍLAGO", values: ["IKDC", "Marx", "Tegner", "KOOS", "WOMAC"] },
    { name: "Protesico", values: ["KSS", "WOMAC", "KOOS"] },
    { name: "FOCA", values: ["IKDC", "Kujala", "Tegner", "WOMET", "SATISFACCIÓN"] },
    { name: "Manual", values: [] },
  ];
  const listTest = [
    "IKDC",
    "Marx",
    "ACL RSI",
    "Tegner",
    "Kujala",
    "Psicológicos",
    "KOOS",
    "WOMET",
    "WOMAC",
    "KSS",
    "SATISFACCIÓN"
  ];

  const handleNameClick = (values, names) => {
    setNameActiveOptionClickList(names);
    setSelectedValues(values);
    setSelectedTest(values);
    setShowModal(true);
  };

  const handleOptionClick = (option) => {
    if (selectedValues.includes(option)) {
      setSelectedValues(selectedValues.filter((val) => val !== option));
      setSelectedTest(selectedValues.filter((val) => val !== option));
    } else {
      setSelectedValues([...selectedValues, option]);
      setSelectedTest([...selectedValues, option]);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    
    const surge = JSON.parse(localStorage.getItem("surgeon"));
    localStorage.removeItem("isKSSFUll");
    
    setSurgeon(surge);

    fetch(`${API_URL}surveys/getFormTypes`)
      .then((res) => res.json())
      .then(
        (result) => {
          let ligaments = result.ligaments.map((v) => {
            return { value: v, label: v };
          });
          setLigaments(ligaments);
          let femoropatels = result.femoropatels.map((v) => {
            return { value: v, label: v };
          });
          setFemoropates(femoropatels);

          let menisc = result.menisc.map((v) => {
            return { value: v, label: v };
          });
          setMeniscs(menisc);
          let osteotomias = result.osteotomias.map((v) => {
            return { value: v, label: v };
          });
          setSteotomias(osteotomias);
          let protesico = result.protesico.map((v) => {
            return { value: v, label: v };
          });
          setProtesicos(protesico);
          let foca = result.foca.map((v) => {
            return { value: v, label: v };
          });
          setFoca(foca);
          let manual = [
            ...result.ligaments,
            ...result.femoropatels,
            ...result.menisc,
            ...result.osteotomias,
            ...result.protesico,
            ...result.foca,
          ];
          manual = [...new Set(manual)];
          let m2 = manual.map((v) => {
            return { value: v, label: v };
          });
          setManual(m2);
        },
        (error) => {
          console.log("error ", error);
        }
      );
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      border: "1px solid #ccc",
      backgroundColor: "#ffffff",
      fontSize: "16px",
      padding: "8px",
      boxShadow: state.isFocused ? "0px 0px 5px #cccccc" : null,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#999",
      transform: state.isDisabled
        ? null
        : state.selectProps.menuIsOpen
        ? "rotate(180deg)"
        : null,
      transition: "transform 0.3s ease",
      pointerEvents: state.isDisabled ? "none" : "auto",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#007aff"
        : state.isFocused
        ? "#f2f2f2"
        : null,
      color: state.isSelected ? "#fff" : state.isFocused ? "#000" : null,
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "10px",
      marginTop: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "192px",
      overflowY: "auto",
    }),
  };

  return (
    <div
      className="text-left loginView-main-div"
      style={{ position: "relative" }}
    >
      <div
        style={{
          marginBottom: "15px",
          alignSelf: "flex-start",
          justifySelf: "flex-start",
          textAlign: "left",
        }}
      >
        <BackButton />
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...INITIAL_STATE }}
        validationSchema={FORM_VALIDATION}
        onSubmit={ON_SUBMIT}
      >
        {(props) => (
          <Form className="">
            <CustomTextInput
              label="FECHA"
              type="date"
              name="fecha"
              // className="form-field"
              placeholder="FECHA"
              defaultValue={dateString}
            />

            <div id="mensajeValidFecha" style={{ display: "none"}}>
              Debe seleccionar una fecha
            </div>

            <div>
              <div className={styles.nameValuesContainer}>
                {nameValues.map((option, i) => (
                  <div
                    key={option.name}
                    style={
                      {
                        borderBottom: nameValues?.length - 1 !== i && "1px solid #afa5a5",
                        padding: "10px 0px",
                        color: "#76b568",
                        fontWeight: "600",
                        cursor: "pointer",
                        'text-transform': 'uppercase'
                      }
                    }
                  >
                    <div onClick={() => handleNameClick(option.values, option.name)}>
                      {option.name} 
                    </div>
                  </div>
                ))}
              </div>

              <div>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={showModal}
                  onClose={handleClose}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <Fade in={showModal}>
                    <div className={styles.mainContainer}>
                      <div className={styles.searchContainer}>
                        <p onClick={handleClose}>GUARDAR </p>
                        {/* <div className={styles.inputContainer}>
                          <FiSearch size={20} color="black" />
                          <input type="text" />
                        </div> */}
                      </div>
                      <div className={styles.optionContainer}>
                        {
                          nameValues.map((option, i) => {
                            
                            //if (option.name == nameActiveOptionClickList) {
                              //return option.values.map((value, i) => (
                              if (i == 0) {
                                return listTest.map((value, i) => (
                                  <div
                                    key={value}
                                    onClick={() => handleOptionClick(value)}
                                    style={{
                                      borderBottom: "1px solid #22222230",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span style={{ marginLeft: "5px" }}>{value}</span>
                                    {selectedValues.includes(value) && (
                                      <BsCheck color={"#76b568"} size={24} />
                                    )}
                                  </div>
                                ))
                              }
                            }
                          //}
                        )}
                      </div>
                    </div>
                  </Fade>
                </Modal>
              </div>
            </div>

            <div id="mensajeValidTest" style={{ display: "none"}}>
              Debe seleccionar al menos una prueba
            </div>

            <div className={styles.selectedValContainer} style={{ gap: '7px' }}>
              <label style={{ color: "#c0c0c0" }}>Selected</label>
              <label>{selectedTest?.map((val) => `${val}, `)}</label>
            </div>

            <div class="form-group d-flex justify-content-center mt-3">
              <button type="submit" class="arrowButton">
                <div>{props.isSubmitting ? "Wait..." : "CODIGO GENERADO"}</div>
                <div className="buttonArrow">
                  <BsArrowRightShort />
                </div>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ScoreSelectView;
