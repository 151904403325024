import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import MainScreen from "./components/main";
import PatientLogin from "./components/patient/login";
import PatientHome from "./components/patient/patientHome";
import SurgeonLogin from "./components/surgeon/loginView";
import SuregonHome from "./components/surgeon/surgeonHome";
import SearchPatientForm from "./components/surgeon/searchForm";
import NewPatient from "./components/surgeon/newPatient";
import NewPatientTwo from "./components/surgeon/newPatientTwo";
import ScoreSelectView from "./components/surgeon/scoreSelectView";
import PatientListView from "./components/surgeon/patientListView";
import PatientScoreView from "./components/surgeon/patientScoreView";
import ConfirmView from "./components/surgeon/confirmView";
import SurveyHomeView from "./components/patient/surveyHome";
import PickerView from "./components/patient/pickerOptionsView";
import PatientInformation from "./components/patient/PatientInformation";

function App() {
  return (
    <Router>
      <div id="divContainer" className="container">
        <section class="vh-100 gradient-custom">
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                <div className=" shadow-2-strong">
                  <div className="card-body  p-3 text-left d-flex justify-content-center">
                    <Routes>
                      <Route path="/" element={<MainScreen />} />
                      <Route path="/patientLogin" element={<PatientLogin />} />
                      <Route path="/patientInformation" element={<PatientInformation />} />
                      <Route path="/patientHome" element={<PatientHome />} />
                      <Route path="/surgonLogin" element={<SurgeonLogin />} />
                      <Route
                        path="/searchPatientForm"
                        element={<SearchPatientForm />}
                      />
                      <Route
                        path="/patientListView"
                        element={<PatientListView />}
                      />
                      <Route
                        path="/patientScoreView"
                        element={<PatientScoreView />}
                      />
                      <Route path="/surveyHome" element={<SurveyHomeView />} />
                      <Route path="/suregonHome" element={<SuregonHome />} />
                      <Route path="/newPatient" element={<NewPatient />} />
                      <Route path="/pickerView" element={<PickerView />} />
                      <Route
                        path="/newPatientTwo"
                        element={<NewPatientTwo />}
                      />
                      <Route
                        path="/scoreSelectView"
                        element={<ScoreSelectView />}
                      />
                      <Route path="/confirmView" element={<ConfirmView />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Router>
  );
}

export default App;
